import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import "./App.css"
// import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { styled } from "@mui/system";

const socket = io.connect('https://ws-names.smorin.app');
// const socket = io.connect('http://192.168.1.11:5001');
console.log("socket definition/connection")

const WhiteTextField = styled(TextField)({
  "& label": {
    color: "white", // Change label color when focused
    fontFamily: 'Roboto Mono',
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white", // Change the outline color when focused
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white", // Change the fieldset outline color
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "white", // Change the fieldset outline color on hover
      borderRadius: 0,
    },
  },
  "& .MuiInputBase-input": {
    color: "white", // Change the input text color
    fontSize: "20px",
    fontFamily: 'Roboto Mono',
  },
});

const WhiteSelect = styled(Select)({
  "& label": {
    color: "white", // Change label color
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white", // Change the fieldset outline color
    },
    "&:hover fieldset": {
      borderColor: "white", // Change the fieldset outline color on hover
    },
  },
  "& .MuiInputBase-input": {
    color: "white", // Change the input text color
  },
});

const CustomButton = styled(Button)`
  font-family: 'Roboto Mono';
  box-shadow: 7px 7px;
`;

const FooterButton = styled(Button)`
  font-family: 'Roboto Mono';
`;

const CustomInputLabel = styled(InputLabel)`
  color: white; // Text color
`;

const CustomSelect = styled(Select)`
  &.MuiSelect-root {
    label {
      color: white; // Label text color
      font-family: 'Roboto Mono';
    }
  }
  &.MuiSelect-select {
    &:focus {
      background-color: black; // Background color when focused
    }
  }
  &.MuiInput-underline::before {
    border-bottom: 1px solid white; // Underline color when not focused
  }
  &.MuiInput-underline::after {
    border-bottom: 1px solid white; // Underline color when focused
  }
`;

const CustomMenuItem = styled(MenuItem)`
  color: black; // Text color
  font-size: 22px;
`;

const App = () => {
    const [currentTime, setCurrentTime] = useState(null);
    const [flashTimeColor, setFlashTimeColor] = useState(false); // State for controlling the flash effect
    const [updateInterval, setUpdateInterval] = useState(1);
    const [commandResult, setCommandResult] = useState('...');
    const [fanCommandResult, setFanCommandResult] = useState('...');
    const [plugState, setPlugState] = useState('...');
    const [kettleCommandResult, setKettleCommandResult] = useState('...');
    // const [fanButtonDisabled, setFanButtonDisabled] = useState(false);
    // const [kettleButtonDisabled, setKettleButtonDisabled] = useState(false);
    const [nextMachineName, setNextMachineName] = useState('');
    const [machineNamePrefix, setMachineNamePrefix] = useState('');
    const [lastMachineName, setLastMachineName] = useState('');
    const [lastMachineNameLaptop, setLastMachineNameLaptop] = useState('');
    const [lastMachineNamePrefix, setLastMachineNamePrefix] = useState('');

    const [lastLetterNumber, setLastLetterNumber] = useState('');
    const [lastLetterNumberLaptop, setLastLetterNumberLaptop] = useState('');


    const [isFlashing, setIsFlashing] = useState(true);
    const [isGreen, setIsGreen] = useState(false);

    const [selectedFruit, setSelectedFruit] = useState('-W10');
    const [showSelectedFruit, setShowSelectedFruit] = useState(false);

    const [claim, setClaim] = useState(false);
    const [claimLaptop, setClaimLaptop] = useState(false);
    const [claimed, setClaimed] = useState('');
    const [claimedLaptop, setClaimedLaptop] = useState('');

    const handleFruitChange = (event) => {
      setSelectedFruit(event.target.value); // Update the selected fruit when the user makes a selection
    };

    const handleClearData = () => {
      socket.emit('clear_data');
      setTimeout(window.location.reload(), 1000);
    }

    const handleRunCommandClick = (command, buttonType) => {
      socket.emit('command', command);
    };
    const handleNewMachineClick = (prefix, middle) => {
      socket.emit('get_computer_name', prefix, middle);
    }
    const handleNewMachineClickLaptop = (prefix, middle) => {
      socket.emit('get_computer_name_laptop', prefix, middle);
    }
    // const handleFanCommandClick = (command, buttonType) => {
    //   socket.emit('fan_command', command);

    //   // Disable the button for 15 seconds
    //   if (buttonType === 'fan') {
    //       setFanButtonDisabled(true);
    //       setTimeout(() => setFanButtonDisabled(false), 15000); // 15 seconds
    //   } else if (buttonType === 'kettle') {
    //       setKettleButtonDisabled(true);
    //       setTimeout(() => setKettleButtonDisabled(false), 15000); // 15 seconds
    //   }
    // };

    const handleClaimClick = (name) => {
      if (claim === true) {
        setClaim(false)
        setClaimed('')
      } else {
        setClaim(true)
        setClaimed(name)
      }
    }

    const handleClaimClickLaptop = (name) => {
      if (claimLaptop === true) {
        // document.getElementById("lastLaptop").style.color = "white"
        setClaimLaptop(false)
        setClaimedLaptop('')
      } else {
        // document.getElementById("lastLaptop").style.color = "orange"
        setClaimLaptop(true)
        setClaimedLaptop(name)
      }
    }

    // useEffect(() => {
    //   // Start the animation when the component mounts
    //   const animationInterval = setInterval(() => {
    //     setIsFlashing((prev) => !prev);
    //   }, 1000); // Toggle every second
  
    //   return () => clearInterval(animationInterval); // Clean up the interval on unmount
    // }, []);

    useEffect(() => {
      const animationInterval = setInterval(() => {
        if (!isGreen) {
          setIsFlashing((prev) => !prev);
        }
      }, 1000); // Toggle every second
  
      return () => clearInterval(animationInterval);
    }, [isGreen]);

    const handleSpanClick = () => {
      if (isFlashing == true) {
        setIsFlashing(false)
        setIsGreen(true)
      } else {
        setIsFlashing(true)
        setIsGreen(false)
      }
    };

    useEffect(() => {
      const emits = () => {
        socket.emit('get_last_computer_name');
        socket.emit('get_last_prefix');
        socket.emit('get_last_computer_name_laptop');
      }

      const handleNextComputerName = (data) => {
        setNextMachineName(data.data)
      }
      const handleLastMachineNamePrefix = (data) => {
        setLastMachineNamePrefix(data.data)
      }
      const handleLastComputerName = (data) => {
        if (claim !== true) {
          setLastMachineName(data.data)
          setLastLetterNumber(data.data)
          // document.getElementById("lastDesktop").style.color = "white"
        } else {
          // document.getElementById("lastDesktop").style.color = "orange"
        }
      }
      const handleLastComputerNameLaptop = (data) => {
        setLastMachineNameLaptop(data.data)
        setLastLetterNumberLaptop(data.data2)
        if (claimLaptop !== true) {
          setLastMachineNameLaptop(data.data)
          setLastLetterNumberLaptop(data.data2)
          // document.getElementById("lastLaptop").style.color = "white"
        } else {
          // document.getElementById("lastLaptop").style.color = "orange"
        }
      }

      socket.on('next_computer_name', handleNextComputerName);
      socket.on('last_computer_name', handleLastComputerName);
      socket.on('last_machine_name_prefix', handleLastMachineNamePrefix);
      socket.on('last_computer_name_laptop', handleLastComputerNameLaptop);

      setInterval(() => emits(), updateInterval * 1000)

      // Clean up the event listeners and intervals when the component unmounts
      return () => {
        socket.off('next_computer_name', handleNextComputerName);
        socket.off('last_computer_name', handleLastComputerName);
        socket.off('last_machine_name_prefix', handleLastMachineNamePrefix);
        socket.off('last_computer_name_laptop', handleLastComputerNameLaptop);
      };
    }, [])

    // const handleCompanyFieldFocus = () => {
    //   document.getElementById("comp-abrev-desc").style.visibility = "inline";
    // }
    // const handleCompanyFieldNoFocus = () => {
    //   document.getElementById("comp-abrev-desc").style.visibility = "hidden";
    // }

    useEffect(() => {
      const fetchSystemData = () => {
        // socket.emit('get_current_time');
        // socket.emit('get_system_info');
        socket.emit('get_plug_state');
        socket.emit('get_kettle_plug_state');
        socket.emit('get_fan_command_result');
        socket.emit('get_command_result');
      }

        const handleConnected = (data) => {
            console.log(data.data);
        };

        const handleFanCommandResult = (data) => {
            setFanCommandResult(data.data)
        }

        const handlePlugState = (data) => {
          // console.log(data)
          setPlugState(data.data)
        }

        const handleCommandResult = (data) => {
          setCommandResult(data.data)
      }

        const handleKettleCommandResult = (data) => {
          setKettleCommandResult(data.data)
      }

        const handleCurrentTime = (data) => {
            const timestamp = new Date(data.data);
            // const formattedTime = `${timestamp.toLocaleDateString()} ${timestamp.toLocaleTimeString()}`;
            const formattedTime = timestamp.toLocaleTimeString();
        
            setCurrentTime(formattedTime);
            setFlashTimeColor(true); // Trigger the flash effect
            setTimeout(() => setFlashTimeColor(false), 500); // Reset the flash effect after 2 seconds
        };

        // let prevCpu = "";
        // let prevRam = "";

        // const handleSystemInfo = (data) => {
        //     setCpuUsage(data.cpu_usage);
        //     setRamUsage(data.ram_usage_percent);
        //     setRamUsageGB(data.ram_usage_gb.toFixed(2));

        //     if (data.cpu_usage !== prevCpu) {
        //       setFlashCpuColor(true);
        //       setTimeout(() => setFlashCpuColor(false), 500); // Reset the flash effect after 2 seconds
        //     }

        //     if (data.ram_usage !== prevRam) {
        //       setFlashRamColor(true);
        //       setTimeout(() => setFlashRamColor(false), 500); // Reset the flash effect after 2 seconds
        //     }

        //     prevCpu = data.cpu_usage;
        //     prevRam = data.ram_usage;
        // };

        socket.on('connected', handleConnected);
        socket.on('plug_state', handlePlugState);
        socket.on('command_result', handleCommandResult);
        // socket.on('current_time', handleCurrentTime);
        // socket.on('system_info', handleSystemInfo);
        socket.on('kettle_plug_state', handleKettleCommandResult);
        socket.on('fan_command_result', handleFanCommandResult);


        // fetchSystemData();

        // Initialize the countdown timer
        // let countdown = updateInterval;
        // const countdownIntervalId = setInterval(() => {
        //     countdown -= 1;
        //     setUpdateInterval(countdown);

        //     if (countdown === 0) {
        //         // Reset the countdown and fetch data
        //         countdown = updateInterval;
        //         setUpdateInterval(countdown);
        //         fetchSystemData();
        //     }
        // }, 1000);

        // setInterval(() => fetchSystemData(), updateInterval * 1000)

        // Clean up the event listeners and intervals when the component unmounts
        return () => {
            socket.off('connected', handleConnected);
            socket.off('command_result', handleCommandResult);
            socket.off('plug_state', handlePlugState);
            socket.off('current_time', handleCurrentTime);
            // socket.off('system_info', handleSystemInfo);
            socket.off('fan_command_result', handleFanCommandResult);
            // socket.off('next_computer_name', handleNextComputerName);
            // socket.off('last_computer_name', handleLastComputerName);
            // socket.off('last_machine_name_prefix', handleLastMachineNamePrefix);
            // clearInterval(countdownIntervalId);
        };
    }, []);

    




    return (
      <center>
        <div>
            {/* <span style={{fontSize: "47px"}}><b>WebSocket API Test</b></span><br/><span style={{fontSize: "22px"}}> Updating {updateInterval === 1 ? 'in ' + updateInterval + ' second' : 'in ' + updateInterval + ' seconds'}</span>
            <br/>
            <span style={{ fontSize: "47px" }}>
              <span style={{ fontSize: "27px" }}>Current Time: </span>
              <br/>
              <span className={false ? 'flash-green' : ''}>{currentTime}</span>
            </span> */}
            <span style={{fontSize: "28px"}}>Smorin's Naming Orchestrator</span>
            <p>
              For supporting on-site onboarding or other machine renaming operations.
            </p>
            <hr></hr>
            {/* <br/> */}
            <span style={{fontSize: "37px"}}><b>Prefix</b></span>
            <br/>
            <br/>
            {lastMachineName ? (
              <>
            {/* <input
              type='text'
              style={{width: "100px", fontSize: "34px", justifyContent: "right"}}
              value={machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix}
              defaultValue={lastMachineNamePrefix}
              onChange={(e) => {
                setMachineNamePrefix(e.target.value.toUpperCase())
                setShowSelectedFruit(true)
              }}
            /> */}
            <span>
            <span style={{paddingRight: "10px"}}>
            {!lastMachineNamePrefix ? (
            <>
            <WhiteTextField 
              id="outlined-basic" 
              label="Company"
              variant="outlined"
              type='text'
              style={{width: "100px", fontSize: "34px", justifyContent: "right", color: "white"}}
              value={machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix}
              defaultValue={lastMachineNamePrefix}
              onChange={(e) => {
                // Extract only the first 4 characters and allow only letters or numbers
                const newValue = e.target.value.slice(0, 4).replace(/[^a-zA-Z0-9-]/g, '');
            
                setMachineNamePrefix(newValue.toUpperCase());
                setShowSelectedFruit(true);
                // handleCompanyFieldFocus();
              }}
            />
            <span style={{fontSize: "40px"}}><span style={{paddingLeft: "10px"}}>-</span></span>
              <span style={{paddingLeft: "10px"}}>
                <WhiteTextField
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFruit}
                  label="OS"
                  onChange={handleFruitChange}
                  select
                >
                  <CustomMenuItem value={'-W10'}>W10</CustomMenuItem>
                  <CustomMenuItem value={'-W11'}>W11</CustomMenuItem>
                  <CustomMenuItem value={'-MAC'}>MAC</CustomMenuItem>
                </WhiteTextField>
              </span>
            </>
            )
            :
            (
              <span>
                <span style={{fontSize: "40px"}}>{machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix}-</span>
            <span style={{paddingLeft: "10px"}}>
              <WhiteTextField
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedFruit}
                label="OS"
                onChange={handleFruitChange}
                select
              >
                <CustomMenuItem value={'-W10'}>W10</CustomMenuItem>
                <CustomMenuItem value={'-W11'}>W11</CustomMenuItem>
                <CustomMenuItem value={'-MAC'}>MAC</CustomMenuItem>
              </WhiteTextField>
            </span>
            </span>
            )}
            </span>
              {/* <span style={{paddingLeft: "10px"}}>
                <WhiteTextField
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFruit}
                  label="OS"
                  onChange={handleFruitChange}
                  select
                >
                  <CustomMenuItem value={'-W10'}>W10</CustomMenuItem>
                  <CustomMenuItem value={'-W11'}>W11</CustomMenuItem>
                  <CustomMenuItem value={'-MAC'}>MAC</CustomMenuItem>
                </WhiteTextField>
              </span> */}
            </span>
            <br/>
            </>
            ) : (<span class="loader"></span>)}
            {/* {lastMachineName && lastMachineName !== "None yet" && (<span style={{fontSize: "35px"}}>{machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix}{selectedFruit}</span>)} */}
            {/* {showSelectedFruit ? (<span style={{fontSize: "44px"}}>{machineNamePrefix}{selectedFruit}</span>) : ''} */}
            <br/>
            {/* <span id="comp-abrev-desc" style={{visibility: "hidden"}}>Company name abreviation.</span> */}
            <hr></hr>
            <br/>
              <span style={{fontSize: "47px"}}><b>Desktop</b></span>
            <br/>
            <br />
            <span>
              {claim ? 
              (
                <>
                  <span style={{fontSize: "22px", color: "#32d332"}}>You claimed:</span>
                  <br/>
                  <span style={{fontSize: '42px', color: "#32d332"}}>
                    {lastMachineNamePrefix + '' + selectedFruit + '-'}{claimed}
                  </span>
                </>
              )
              :
              (
                <>
                {lastMachineName && (lastMachineName !== "None yet" ? <span style={{fontSize: "22px"}}>Unclaimed:</span> : '')}
                <br/>
                <span id="lastDesktop" style={{fontSize: '42px'}}>
                  {lastMachineName ? (lastMachineName !== "None yet" ? lastMachineNamePrefix + '' + selectedFruit + '-' + lastMachineName : <span><CustomButton variant="contained" style={{fontSize: "22px", borderRadius: "0px"}} onClick={() => handleNewMachineClick(machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix, selectedFruit) & handleSpanClick()}>Generate</CustomButton></span>) : (<span class="loader"></span>)}
                </span>
                </>
              )
              }
              {/* <span              
                style={{
                  fontSize: '42px',
                  paddingLeft: '7px',
                  animation: isFlashing ? 'flashAnimation 1s infinite' : 'none',
                  color: isGreen ? 'green' : 'orange',
                  cursor: 'pointer',
                }}
                onClick={handleSpanClick}
              >
                <AccessTimeFilledIcon style={{fontSize: "32px"}} />
              </span> */}
            </span>
              {/* <span style={{fontSize: "32px"}}>{lastMachineName}</span><span style={{paddingLeft: "7px"}}><AccessTimeFilledIcon /></span> */}
            <br/>
            <br/>
              <span>
              {lastMachineName && (!claim ? 
                lastMachineName !== "None yet" ? <CustomButton variant="contained" style={{fontSize: "22px", borderRadius: "0px"}} onClick={() => handleNewMachineClick(machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix, selectedFruit) & handleClaimClick(lastMachineName)}>Claim</CustomButton> : ''
                : 
                lastMachineName !== "None yet" ? <CustomButton variant="contained" style={{fontSize: "22px", borderRadius: "0px"}} onClick={() => handleClaimClick(lastMachineName)}>Done</CustomButton> : ''
              )}
              </span>
              {claim && (
                <>
                <br/>
                <br/>
                  <span id="lastDesktop" style={{fontSize: '22px', color: "#a0a0a0"}}>
                    Current unclaimed: {lastMachineName}
                  </span>
                </>
              )}
            <br />
            <br/>
            <hr></hr>
            <br/>
              <span style={{fontSize: "47px"}}><b>Laptop</b></span>
            <br/>
            <br/>
            <span>
              {claimLaptop ? 
              (
                <>
                  <span style={{fontSize: "22px", color: "#32d332"}}>You claimed:</span>
                  <br/>
                  <span style={{fontSize: '42px', color: "#32d332"}}>
                  {lastMachineNamePrefix + '' + selectedFruit + '-'}{claimedLaptop}
                  </span>
                  {/* <br/>
                  <span id="lastLaptop" style={{fontSize: '22px', color: "#a0a0a0"}}>
                    Current unclaimed: {lastMachineNameLaptop}
                  </span> */}
                </>
              )
              :
              (
                <>
                {lastMachineName ? (lastMachineNameLaptop !== "None yet" ? <span style={{fontSize: "22px"}}>Unclaimed:</span> : '') : ''}
                <br/>
                <span id="lastLaptop" style={{fontSize: '42px'}}>
                  {lastMachineName ? (lastMachineNameLaptop !== "None yet" ? lastMachineNamePrefix + '' + selectedFruit + '-' + lastMachineNameLaptop : <CustomButton variant='contained' style={{fontSize: "22px", borderRadius: "0px"}} onClick={() => handleNewMachineClickLaptop(machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix, selectedFruit)}>Generate</CustomButton>) : (<span class="loader"></span>)}
                </span>
                </>
              )
              }
              {/* <span              
                style={{
                  fontSize: '42px',
                  paddingLeft: '7px',
                  animation: isFlashing ? 'flashAnimation 1s infinite' : 'none',
                  color: isGreen ? 'green' : 'orange',
                  cursor: 'pointer',
                }}
                onClick={handleSpanClick}
              >
                <AccessTimeFilledIcon style={{fontSize: "32px"}} />
              </span> */}
              <br/>
              <br/>
            </span>
              {/* {lastMachineNameLaptop === "None yet" ? <CustomButton style={{fontSize: "32px"}} onClick={() => handleNewMachineClickLaptop(machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix, selectedFruit)}>Generate</CustomButton> : ''} */}
              
              {lastMachineNameLaptop && (!claimLaptop ? 
                lastMachineNameLaptop !== "None yet" ? <CustomButton variant="contained" style={{fontSize: "22px", borderRadius: "0px"}} onClick={() => handleNewMachineClickLaptop(machineNamePrefix ? machineNamePrefix : lastMachineNamePrefix, selectedFruit) & handleClaimClickLaptop(lastMachineNameLaptop)}>Claim</CustomButton> : ''
                : 
                lastMachineNameLaptop !== "None yet" ? <CustomButton variant="contained" style={{fontSize: "22px", borderRadius: "0px"}} onClick={() => handleClaimClickLaptop(lastMachineNameLaptop)}>Done</CustomButton> : ''
              )}

              {claimLaptop && (
                <>
                <br/>
                <br/>
                  <span id="lastDesktop" style={{fontSize: '22px', color: "#a0a0a0"}}>
                    Current unclaimed: {lastMachineNameLaptop}
                  </span>
                </>
              )}
            <br />
            <br/>
            
            {/* <span style={{ fontSize: "47px" }}>
              <span style={{ fontSize: "27px" }}>CPU Usage: </span>
              <br/>
              <span className={flashCpuColor ? 'flash-green' : ''}>{cpuUsage}%</span>
            </span>
            <br />
            <br />
            <span style={{ fontSize: "47px" }}>
              <span style={{ fontSize: "27px" }}>RAM Usage: </span>
              <br/>
              <span className={flashRamColor ? 'flash-green' : ''}>{ramUsage}%</span>
              <br/>
              <span className={flashRamColor ? 'flash-green' : ''}>{ramUsageGB}GB</span>
            </span>
            <br/>
            <br /> */}
            {/* <div>
                <span style={{fontSize: "22px"}}>
                  <span>Server room fan state:</span>
                  <br/>
                  <span style={{fontSize: "37px"}}>
                    {plugState}
                  </span>
                  <br/>
                  <span>{plugState === "On" ? (
                    <CustomButton disabled={fanButtonDisabled} onClick={() => handleFanCommandClick('kasa --host "192.168.1.96" --type=plug  off')}>
                      Turn Off
                    </CustomButton>
                    ) : (
                    <CustomButton disabled={fanButtonDisabled} onClick={() => handleFanCommandClick('kasa --host "192.168.1.96" --type=plug  on')}>
                      Turn On
                    </CustomButton>
                    )}
                  </span>
                </span>
            </div>
            <br/>
            <div>
                <span style={{fontSize: "22px"}}><span>Electric kettle state:</span><br/><span style={{fontSize: "37px"}}>{kettleCommandResult}</span><br/><span>{kettleCommandResult === "On" ? (<CustomButton onClick={() => handleFanCommandClick('kasa --host "192.168.1.216" --type=plug  off')}>Turn Off</CustomButton>) : (<CustomButton onClick={() => handleFanCommandClick('kasa --host "192.168.1.216" --type=plug  on')}>Turn On</CustomButton>)}</span></span>
            </div>
            <br/>
            <div>
              <input
                type='text'
                placeholder='Command to run'
                value={commandToRun}
                onChange={(e) => {
                  setCommandToRun(e.target.value)
                }}
              />
              <CustomButton onClick={() => handleRunCommandClick(commandToRun)}>Run command</CustomButton>
            </div>
            <div>
              Command result:
              <br/>
              {commandResult}
            </div> */}
            {/* <div>
                <input
                    type="text"
                    placeholder="Filter processes"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
                {runningProcesses
                    .filter((process) =>
                        process.toLowerCase().includes(filter.toLowerCase())
                    )
                    .map((filteredProcess, index) => (
                        <div key={index}>
                            <span>{filteredProcess}</span>
                        </div>
                    ))}
            </div> */}

            <br/>
            <div>
              <footer>
                <FooterButton onDoubleClick={() => handleClearData()}>Clear/Reset Data</FooterButton>
                <br/>
                <br/>
                {/* <br/>
                *a <a href='https://smorin.app' target='_blank'>smorin</a> test
                <br/> */}
              </footer>
            </div>
        </div>
      </center>
    );
};

export default App;
